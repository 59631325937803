export const languages = [
    { value: 'Afrykanerski', text: 'Afrykanerski', code: 'af' },
    { value: 'Albański', text: 'Albański', code: 'sq' },
    { value: 'Amharski', text: 'Amharski', code: 'am' },
    { value: 'Angielski', text: 'Angielski', code: 'en' },
    { value: 'Arabski', text: 'Arabski', code: 'ar' },
    { value: 'Armeński', text: 'Armeński', code: 'hy' },
    { value: 'Azerski', text: 'Azerski', code: 'az' },
    { value: 'Baskijski', text: 'Baskijski', code: 'eu' },
    { value: 'Bengalski', text: 'Bengalski', code: 'bn' },
    { value: 'Białoruski', text: 'Białoruski', code: 'be' },
    { value: 'Birmański', text: 'Birmański', code: 'my' },
    { value: 'Bośniacki', text: 'Bośniacki', code: 'bs' },
    { value: 'Bretoński', text: 'Bretoński', code: 'br' },
    { value: 'Bułgarski', text: 'Bułgarski', code: 'bg' },
    { value: 'Chichewa', text: 'Chichewa', code: 'ny' },
    { value: 'Chiński', text: 'Chiński', code: 'zh' },
    { value: 'Czeski', text: 'Czeski', code: 'cs' },
    { value: 'Duński', text: 'Duński', code: 'da' },
    { value: 'Estoński', text: 'Estoński', code: 'et' },
    { value: 'Fiński', text: 'Fiński', code: 'fi' },
    { value: 'Francuski', text: 'Francuski', code: 'fr' },
    { value: 'Galicyjski', text: 'Galicyjski', code: 'gl' },
    { value: 'Gruzinski', text: 'Gruzinski', code: 'ka' },
    { value: 'Grecki', text: 'Grecki', code: 'el' },
    { value: 'Haitski', text: 'Haitski', code: 'ht' },
    { value: 'Hinduski', text: 'Hinduski', code: 'hi' },
    { value: 'Hiszpański', text: 'Hiszpański', code: 'es' },
    { value: 'Hmong', text: 'Hmong', code: 'hmn' },
    { value: 'Holenderski', text: 'Holenderski', code: 'nl' },
    { value: 'Hebrajski', text: 'Hebrajski', code: 'he' },
    { value: 'Indonezyjski', text: 'Indonezyjski', code: 'id' },
    { value: 'Irlandzki', text: 'Irlandzki', code: 'ga' },
    { value: 'Islandzki', text: 'Islandzki', code: 'is' },
    { value: 'Japoński', text: 'Japoński', code: 'ja' },
    { value: 'Kambodżański', text: 'Kambodżański', code: 'km' },
    { value: 'Kataloński', text: 'Kataloński', code: 'ca' },
    { value: 'Kazachski', text: 'Kazachski', code: 'kk' },
    { value: 'Koreański', text: 'Koreański', code: 'ko' },
    { value: 'Kurdeński', text: 'Kurdeński', code: 'ku' },
    { value: 'Laotański', text: 'Laotański', code: 'lo' },
    { value: 'Łaciński', text: 'Łaciński', code: 'la' },
    { value: 'Litewski', text: 'Litewski', code: 'lt' },
    { value: 'Łotewski', text: 'Łotewski', code: 'lv' },
    { value: 'Macedoński', text: 'Macedoński', code: 'mk' },
    { value: 'Malajski', text: 'Malajski', code: 'ms' },
    { value: 'Malajalam', text: 'Malajalam', code: 'ml' },
    { value: 'Maltanski', text: 'Maltanski', code: 'mt' },
    { value: 'Mongolski', text: 'Mongolski', code: 'mn' },
    { value: 'Nepalski', text: 'Nepalski', code: 'ne' },
    { value: 'Niderlandzki', text: 'Niderlandzki', code: 'nl' },
    { value: 'Norweski', text: 'Norweski', code: 'no' },
    { value: 'Perski', text: 'Perski', code: 'fa' },
    { value: 'Polski', text: 'Polski', code: 'pl' },
    { value: 'Portugalski', text: 'Portugalski', code: 'pt' },
    { value: 'Rumuński', text: 'Rumuński', code: 'ro' },
    { value: 'Rosyjski', text: 'Rosyjski', code: 'ru' },
    { value: 'Serbski', text: 'Serbski', code: 'sr' },
    { value: 'Słowacki', text: 'Słowacki', code: 'sk' },
    { value: 'Słoweński', text: 'Słoweński', code: 'sl' },
    { value: 'Somalijski', text: 'Somalijski', code: 'so' },
    { value: 'Szwedzki', text: 'Szwedzki', code: 'sv' },
    { value: 'Tamilski', text: 'Tamilski', code: 'ta' },
    { value: 'Tajski', text: 'Tajski', code: 'th' },
    { value: 'Turecki', text: 'Turecki', code: 'tr' },
    { value: 'Ukraiński', text: 'Ukraiński', code: 'uk' },
    { value: 'Uzbecki', text: 'Uzbecki', code: 'uz' },
    { value: 'Węgierski', text: 'Węgierski', code: 'hu' },
    { value: 'Wietnamski', text: 'Wietnamski', code: 'vi' },
    { value: 'Włoski', text: 'Włoski', code: 'it' },
    { value: 'Zhuang', text: 'Zhuang', code: 'za' }
  ];