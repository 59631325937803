<template>

  <div class="pt-2">

    <b-input-group>

      <b-form-input v-model="keyword" placeholder="Wpisz frazę której szukasz..." size="lg" autofocus v-on:keyup.enter="getSuggestions" :disabled="isSearching"></b-form-input>

      <b-input-group-append>

        <b-button variant="outline-white" v-if="keyword" @click="keyword = ''">

          <span class="material-symbols-outlined mt-1">close</span>

        </b-button>

        <b-button variant="outline-white" v-if="!keyword" @click="getSuggestions()">

          <span class="material-symbols-outlined" style="vertical-align:middle;">search</span> Szukaj

        </b-button>

      </b-input-group-append>

    </b-input-group>

    <Loader v-if="isSearching"/>

    <div v-if="!keyword" class="pt-2">

      lub kliknij:
      <span class="text-primary pointer ml-1" @click="loadFromExample('dieta')">dieta</span>,
      <span class="text-primary pointer ml-1" @click="loadFromExample('mlm')">mlm</span>,
      <span class="text-primary pointer ml-1" @click="loadFromExample('zarabianie w internecie')">zarabianie w internecie</span>,
      <span class="text-primary pointer ml-1" @click="loadFromExample('iphone')">iphone</span>,
      <span class="text-primary pointer ml-1" @click="loadFromExample('makijaż')">makijaż</span>

    </div>

    <div class="mt-2" v-if="(results.length > 0) && keyword">

      <b-form-group>
        <b-form-radio-group v-model="mode" :options="modeOptions" buttons button-variant="light">
        </b-form-radio-group>
      </b-form-group>

      <b-row align-v="center" style="min-height: 40px;">

        <b-col lg="8">

          <span v-if="mode == 'keywords'">Ilość słów kluczowych: </span>
          <span v-if="mode == 'questions'">Ilość pytań: </span>

          <B>{{ results.length | splitNumber }}</B> |


          <span class="text-primary pointer" @click="randomKeyword">
            <span class="material-symbols-sharp align-middle">shuffle</span>
            Losuj słowo
          </span>


        </b-col>

      </b-row>

      <div v-if="selected.length" class="mt-4 pt-3" style="border-top:1px solid #ddd;">

        <label class="small">Wybrana fraza:</labeL>

        <b-row align-v="center" style="min-height: 40px;">

          <b-col lg="4">

            <b-form-input v-model="selected" placeholder="" v-on:keyup.enter="generateFinish()"></b-form-input>

          </b-col>

          <b-col lg="4">

            <label for="sb-inline" class="small">Ilość znaków: </label>
            <b-form-spinbutton class="ml-2" id="sb-inline" v-model="numberOfSentences" inline  min="1000" max="4000" step="500"></b-form-spinbutton>

          </b-col>

          <b-col lg="4">



          </b-col>

        </b-row>

        <b-row align-v="center" class="mt-2" style="min-height: 40px;">

          <b-col lg="4">

            <label class="small">Wybierz styl:</label>

            <b-input-group>

              <b-form-select v-model="style" ref="style" :options="styles"></b-form-select>

            </b-input-group>

          </b-col>

        </b-row>

  
        <b-row align-v="center" class="mt-2" style="min-height: 40px;">

          <b-col lg="4">

            <label class="small">Wybierz język:</label>

            <b-input-group>

              <b-form-select v-model="lang" ref="lang" :options="languages"></b-form-select>

            </b-input-group>

          </b-col>

        </b-row>
  

        <b-row align-v="center" class="mt-2" style="min-height: 40px;">

          <b-col lg="4">

            <label class="small">Wybierz strukturę:</label>

            <b-input-group>

              <b-form-select v-model="structure" ref="structure" :options="structures"></b-form-select>

            </b-input-group>

          </b-col>


          <b-col lg="4">

            <label class="small">Dodatkowo:</labeL>

            <b-form-checkbox v-model="addEmoji" value="true">Dodaj emoji</b-form-checkbox>
            <b-form-checkbox v-model="addCallToFollow" value="true">Dodaj wezwanie do obserwowania</b-form-checkbox>
            <b-form-checkbox v-model="addHashtags" value="true">Dodaj hashtagi</b-form-checkbox>


          </b-col>

          <b-col lg="4">

            <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="float-right">
              <span class="material-symbols-outlined align-middle">
                bolt
              </span>
              Generuj Post
            </b-button>

          </b-col>

        </b-row>



      </div>


      <b-card header-tag="header" class="mt-4" v-if="isFinish">

        <template #header>

          <h6 class="mb-0 mt-2 float-left">
            <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje Twój Post <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
            <span v-if="finishContent">Wygenerowany Post</span>
          </h6>

          <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

            <span class="material-symbols-outlined mt-1">close</span>

          </b-button>

        </template>

        <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">

          Wygenerowany post dla frazy <B>{{ selected }}</B>:

          <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

          <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

            <span class="material-symbols-sharp align-middle">content_copy</span>
            Kopiuj do schowka

          </div>

        </blockquote>

      </b-card>

      <b-table id="table" class="mt-4" striped hover :fields="tableFields" :items="results" :per-page="perPage" :current-page="currentPage">

        <template #cell(keyword)="data">
          <b-form-radio ref="selectedKeywords" v-model="selected" name="selectedKeywords" :value="data.value">{{ data.value }}</b-form-radio>
        </template>

        <template #cell(volume)="data">
          {{ data.value | splitNumber }}
        </template>

        <template #cell(competition)="data">
          <span v-if="(data.value <= 100 && data.value >= 75)" class="text-danger">Wysoka <small><sup>({{ data.value }})</sup></small></span>
          <span v-if="(data.value < 75 && data.value >= 50)" class="text-warning">Średnia <small><sup>({{ data.value }})</sup></small></span>
          <span v-if="(data.value < 50 && data.value >= 25)" class="text-primary">Niska <small><sup>({{ data.value }})</sup></small></span>
          <span v-if="(data.value < 25 && data.value >= 0)" class="text-success">Bardzo niska <small><sup>({{ data.value }})</sup></small></span>
        </template>


      </b-table>

      <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table"
      ></b-pagination>

      <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>
      <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

    </div>

  </div>
</template>

<script>
import Loader from '@/elements/Loader';
import { languages } from './../../../../resources/languages.js';

export default {
  name: 'AppSeoWriterAi',
  components: {
    Loader
  },
  data() {
    return {
      perPage: 100,
      currentPage: 1,
      keyword: '',
      lang: "Polski",
      languages,
      isSearching: false,
      isFinish: false,
      tableFields: [
        {
          key: 'keyword',
          label: 'Słowo kluczowe',
          sortable: false
        },
        {
          key: 'volume',
          label: 'Wyszukiwań miesięcznie',
          sortable: true
        },
        {
          key: 'competition',
          label: 'Konkurencja',
          sortable: true
        }
      ],
      results: [],
      resultsOriginal: [],
      mode: 'keywords',
      modeOptions: [
        {
          text: 'Słowa kluczowe',
          value: 'keywords',
          html: '<span class="material-symbols-outlined align-middle">list</span> Słowa kluczowe'
        },
        {
          text: 'Pytania',
          value: 'questions',
          html: '<span class="material-symbols-outlined align-middle">quiz</span> Pytania'
        }
      ],
      selected: [],
      result: "",
      numberOfSentences: 2500,
      selectedText: '',
      addEmoji: false,
      addCallToFollow: false,
      addHashtags: false,
      structure: '',
      structures: [
        "7 sposobów na...",
        "6 TIPÓW na...",
        "5 błędów...",
        "6 kroków do",
        "5 tricków  zwiększających",
        "5 sposobów na...",
        "5 kroków do...",
        "3 sekrety odkrywania...",
        "4 najważniejsze elementy...",
        "8 niezawodnych metod na...",
        "7 rzeczy, które musisz wiedzieć o...",
        "9 zasad, które na zawsze zmienią twoje podejście do...",
        "10 mitów dotyczących...",
        "5 rzeczy, które możesz nauczyć się od...",
        "6 strategii na podniesienie skuteczności używania...",
        "7 sprawdzonych sposobów na poprawę...",
        "8 faktów, które zmienią twoje spojrzenie na...",
        "5 rzeczy, które powinieneś unikać w...",
        "4 kluczowe kroki do osiągnięcia...",
        "7 narzędzi, które zrewolucjonizują twoją pracę z",
        "9 rzeczy, które warto wiedzieć przed zakupem...",
        "10 pomysłów na ulepszenie...",
        "3 rzeczy, które zaskoczą cię w kwestii",
        "6 błędnych założeń dotyczących...",
        "4 tajemnice do mistrzowskiego opanowania...",
        "7 znaków, że powinieneś zmienić swoje podejście do...",
        "5 kroków, które przyspieszą twoją drogę do.."
      ],
      style: '',
      styles: [
        "Narracyjny",
        "Opisowy",
        "Perswazyjny",
        "Informatywny/Edukacyjny",
        "Argumentacyjny - za i przeciw",
        "Problemowo - rozwiązaniowy",
        "Poradnikowy",
        "Recenzyjny",
        "Analityczny",
        "Rozważań/refleksji",
        "Satyryczny",
        "Badawczy",
        "Porównawczy",
        "Listowy w formie list",
        "Studium przypadku",
        "Historyczny",
        "Prognozujący"
      ],
      finishContent: '',
      schemaKeywords: [
        "Wciel się w autora bloga i napisz artykuł na bloga na temat [SŁOWO KLUCZOWE]. Artykuł ma być napisany na [ILOŚĆ ZNAKÓW] znaków i posiadać strukturę [STRUKTURA]  Artykuł powinien być napisany w stylu [STYL]. Pisz językiem prostym do zrozumienia. Pisz bezpośrednio jakbyś pisał do przyjaciela. Artykuł ma mieć charakter artykułu na bloga."
      ],
      schemaQuestions: [
        "Wciel się w autora bloga i napisz artykuł na bloga w którym odpowiesz na pytanie: [SŁOWO KLUCZOWE]. Artykuł ma być napisany na [ILOŚĆ ZNAKÓW] znaków i posiadać strukturę [STRUKTURA]  Artykuł powinien być napisany w stylu [STYL]. Pisz językiem prostym do zrozumienia. Pisz bezpośrednio jakbyś pisał do przyjaciela. Artykuł ma mieć charakter artykułu na bloga."
      ]
    }


  },
  methods: {

    randomKeyword: function() {

      this.finishContent = ""
      this.result = ""

      this.selected = ""

      var selected = ""

      var item = this.results[Math.floor(Math.random() * this.results.length)];

      selected = item.keyword;

      this.selected = selected

    },

    generateFinish: function() {

      if(!this.selected) return false;

      this.$refs.generatePost.disabled = true

      this.isFinish = true

      this.finishContent = ""
      this.result = ""

      var mix = []

      if(this.mode == 'keywords') {

        mix = this.schemaKeywords

      }

      if(this.mode == 'questions') {

        mix = this.schemaQuestions

      }

      var query = mix[Math.floor(Math.random() * mix.length)];

      query = query.replaceAll('[SŁOWO KLUCZOWE]', this.selected)
      query = query.replaceAll('[STRUKTURA]', this.structure)
      query = query.replaceAll('[STYL]', this.style)
      query = query.replaceAll('[ILOŚĆ ZNAKÓW]', "(" + this.numberOfSentences + " znaków)")


      if(this.addEmoji) {

        query = query + "Dodaj emoji."

      }

      if(this.addCallToFollow) {

        query = query + "Dodaj wezwanie do obserwowania."

      }

      if(this.addHashtags) {

        query = query + "Dodaj hashtagi na końcu."

      }

      query = query + "Napisz to koniecznie(!) w języku: " + this.lang;


      var doneText = "";

      this.axios.post(window.API_URL + "text/generateFromAI/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {

        let data = response.data

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }

        doneText = data.result;

        this.finishContent = doneText;

        this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

        this.$refs.generatePost.disabled = false
        this.generatedPostTitle = "Wygenerowany Post"

      }).catch(function() {

        this.$refs.generatePost.disabled = false

      })



    },

    setKeywordsMode: function () {

      this.isSearching = true

      this.selected = []

      this.results = this.resultsOriginal

      this.isSearching = false

      this.isFinish = false

      this.fastReel = ""

      this.finishContent = ""

    },

    setQuestionsMode: function () {

      this.isSearching = true
      this.isFinish = false
      this.fastReel = ""

      this.selected = []

      var filtered = []
      var questions = ["kiedy", "Jak", "jak", "co ", " co ", "dlaczego ", "na czym"]
      var results = this.results

      questions.forEach(function (item) {

        var filtered_tmp = results.filter(function(el) {
          return el.keyword.indexOf(item) > -1
        });

        filtered = filtered.concat(filtered_tmp);

      });

      filtered = filtered.filter((v,i,a)=>a.findIndex(v2=>(v2.keyword===v.keyword))===i)

      this.results = filtered

      this.isSearching = false

      this.isFinish = false
      this.fastReel = ""

      this.finishContent = ""

    },

    getSuggestions: function () {

      this.isSearching = true
      this.isFinish = false
      this.fastReel = ""


      this.results = []
      this.selected = []
      this.mode = 'keywords'

      this.axios.post(window.API_URL + "text/getKeywordData/", { "keyword": this.keyword }, { withCredentials: true }).then((response) => {

        let data = response.data

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }

        this.results = data
        this.resultsOriginal = data

        this.isSearching = false

      })

    },

    loadFromExample: function(keyword) {

      this.keyword = keyword
      this.getSuggestions()

    },

    copyResults: function() {

      this.$refs.finishContent.style.display = "block";

      this.$refs.finishContent.focus();

      document.execCommand('copy');

      this.$refs.finishContent.style.display = "none"

      window.scrollTo(0, 0)

      this.$bvToast.toast('Post został skopiowany do schowka', {
        title: 'Skopiowano',
        autoHideDelay: 5000,
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-top-center',
        noCloseButton: true
      })

    },

    copy: function() {

      this.$refs.selectedText.style.display = "block";

      this.$refs.selectedText.focus();

      document.execCommand('copy');

      window.scrollTo(0, 0)

      this.$refs.selectedText.style.display = "none"

      this.$bvToast.toast('Słowa kluczowe zostały skopiowane do schowka', {
        title: 'Skopiowano',
        autoHideDelay: 5000,
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-top-center',
        noCloseButton: true
      })


    }

  },
  computed: {
    rows() {
      return this.results.length
    }
  },
  mounted() {

    if (localStorage.generators_language) {

      this.lang = localStorage.generators_language;

    } else {

      localStorage.generators_language = "Polski"
      this.lang = "Polski"
    }

  },
  watch: {

    lang(data) {

      localStorage.generators_language = data;

    }

  },
  created() {

    this.$watch('selected', (value) => {

      this.selectedText = value

      this.finishContent = ""
      this.result = ""
      this.isFinish = false

    })


    this.$watch('mode', (value) => {

      if(value == 'questions') {

        this.setQuestionsMode()

      }

      if(value == 'keywords') {

        this.setKeywordsMode()

      }

    })

    this.$watch('finishContent', (value) => {

      var lines = value.split(/\r|\r\n|\n/);

      this.finishLinesCount = lines.length;

    })







  }

}
</script>

<style>

.pointer { cursor: pointer; }

</style>




