<template>

  <div class="pt-2">

    <div>

      <b-row align-v="center">

        <b-col lg="12">

          <div class="mt-4">

            <div class="mt-3">

              <label>Wybierz temat postów motywacyjnych:</label>

              <b-input-group>

                <b-form-select v-model="selectedTopic" ref="selectedTopic" size="lg" :options="topics" v-on:change="setTopic()"></b-form-select>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>lub wpisz własny:</label>

              <b-input-group>

                <b-form-input v-model="topic" size="lg" ref="topic" placeholder="np. praca, pieniądze, motywacja, ciężka praca."></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Wybierz język:</label>

              <b-input-group>

                <b-form-select v-model="lang" ref="lang" size="lg" :options="languages"></b-form-select>

              </b-input-group>

            </div>

          </div>

        </b-col>

      </b-row>

      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">

        </b-col>

        <b-col lg="6">

          <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
            Generuj posty
          </b-button>

        </b-col>

      </b-row>



      <div class="mt-2" >

        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje posty <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowane posty</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">

            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>

        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>

    </div>

  </div>

</template>

<script>
import { languages } from './../../../../resources/languages.js';

export default {
  name: 'AppMotivationalPostAi',
  components: {
  },
  data() {
    return {
      isFinish: false,
      result: "",
      selectedText: '',
      query: "",
      topic: "",
      lang: "Polski",
      languages,
      selectedTopic: "",
      topics: [
        "Siła miłości w relacjach.",
        "Szczęście: stan umysłu, a nie stan konta.",
        "Pieniądze vs. pasja: co jest ważniejsze?",
        "Prawdziwe przyjaźnie i ich moc.",
        "Jak miłość do siebie wpływa na twoje relacje?",
        "Podążaj za swoją pasją, a pieniądze przyjdą.",
        "Nie można kupić miłości.",
        "Jak zaakceptować siebie i pokochać swoje życie.",
        "Budowanie trwałych relacji.",
        "Jak prawdziwa miłość pomaga nam rosnąć.",
        "Pieniądze i szczęście: Rozwiewanie mitów.",
        "Jak budować silne więzi emocjonalne.",
        "Sztuka bycia wdzięcznym za to, co masz.",
        "Uzdrawianie relacji poprzez przebaczenie.",
        "Czy pieniądze naprawdę rządzą światem?",
        "Czy miłość do siebie może zmienić twoje życie?",
        "Oszczędzanie pieniędzy: kroki do finansowej wolności.",
        "Rola zaufania w zdrowych relacjach.",
        "Miłość czy kariera: Jak zrównoważyć swoje życie.",
        "Jak pieniądze wpływają na nasze codzienne życie.",
        "Jak budować relacje, które trwają.",
        "Sztuka dzielenia się miłością.",
        "Pieniądze jako narzędzie, a nie cel.",
        "Jak podtrzymywać długotrwałe relacje.",
        "Pierwszy krok do miłości: akceptacja siebie.",
        "Jak pieniądze wpływają na nasze zdrowie emocjonalne.",
        "Zrozumienie, jak miłość naprawdę działa.",
        "Inwestowanie w siebie: najlepszy sposób na zarabianie pieniędzy.",
        "Jak konflikt może wzmacniać relacje.",
        "Rola miłości w utrzymaniu zdrowia psychicznego.",
        "Pieniądze a długotrwałe szczęście: Rozbijanie stereotypów.",
        "Zdrowe relacje: Jak je zbudować i utrzymać.",
        "Uczucie wdzięczności: Sekret szczęścia.",
        "Zrozumienie, jak pieniądze wpływają na twoje relacje.",
        "Jak pokochać siebie mimo swoich wad.",
        "Finansowa niezależność: Jak to osiągnąć.",
        "Budowanie relacji: poza pierwszym wrażeniem.",
        "Miłość czy pieniądze: Co jest bardziej ważne?",
        "Pieniądze a poczucie bezpieczeństwa: prawda czy mit?",
        "Jak budować autentyczne relacje.",
        "Miłość a lęk: Jak jedno wpływa na drugie.",
        "Pieniądze a szczęście: Nowe spojrzenie.",
        "Jak wykorzystać swoje pasje do zarabiania pieniędzy.",
        "Jak komunikacja wpływa na relacje.",
        "Miłość a poczucie własnej wartości: Jak jedno wpływa na drugie.",
        "Pieniądze a wolność: Rozbijanie mitów.",
        "Jak podtrzymać relacje na odległość.",
        "Budowanie szczęścia poprzez miłość do siebie.",
        "Zarządzanie pieniędzmi: Klucz do wolności finansowej.",
        "Jak utrzymanie zdrowych relacji wpływa na twoje szczęście.",
        "Pokonanie lęku: kroki do odwagi.",
        "Pieniądze: narzędzie, nie definicja.",
        "Słabości jako siła: zmiana perspektywy.",
        "Budowanie charakteru: Cnota cierpliwości.",
        "Lęk a miłość: dwa przeciwstawne bieguny.",
        "Pieniądze a wartość osobista: rozróżnianie faktów od fikcji.",
        "Pokonanie słabości: droga do samodoskonalenia.",
        "Rola determinacji w budowaniu charakteru.",
        "Zrozumienie swojego lęku: pierwszy krok do pokonania go.",
        "Pieniądze a szczęście: rozwiewanie mitów.",
        "Słabości jako katalizator wzrostu.",
        "Budowanie charakteru: Cnota odwagi.",
        "Jak zmierzyć się z lękiem i wygrać.",
        "Rola pieniędzy w naszym codziennym życiu.",
        "Przełamywanie swoich słabości: klucz do sukcesu.",
        "Budowanie charakteru: Cnota szczerości.",
        "Pokonaj swój lęk: Czy jesteś gotów na wyzwanie?",
        "Zarządzanie pieniędzmi: ścieżka do wolności finansowej.",
        "Pokonanie słabości: nie jesteś sam.",
        "Budowanie charakteru: Cnota wytrwałości.",
        "Lęk jako przeciwnik czy nauczyciel?",
        "Pieniądze a wartości: jaki jest twój priorytet?",
        "Słabości jako źródło siły.",
        "Budowanie charakteru: Cnota lojalności.",
        "Jak zmienić lęk w motywację.",
        "Pieniądze a etyka: gdzie jest granica?",
        "Transformacja słabości w siłę.",
        "Budowanie charakteru: Cnota empatii.",
        "Lęk a ryzyko: jak zrozumieć swoje granice.",
        "Pieniądze: narzędzie do zmiany, a nie cel.",
        "Jak pokonać swoje słabości i osiągnąć swoje cele.",
        "Budowanie charakteru: Cnota odpowiedzialności.",
        "Lęk przed nieznanym: jak go pokonać.",
        "Zarabianie i oszczędzanie: zrozumienie wartości pieniądza.",
        "Przetrwać słabości: podróż do wnętrza siebie.",
        "Budowanie charakteru: Cnota szacunku.",
        "Jak przezwyciężyć lęk przed porażką.",
        "Pieniądze a poczucie bezpieczeństwa: prawda czy mit?",
        "Jak przekształcić słabości w możliwości.",
        "Budowanie charakteru: Cnota samodyscypliny.",
        "Lęk a sukces: Jak jeden wpływa na drugi.",
        "Pieniądze a spokój ducha: jak znaleźć równowagę.",
        "Strategie radzenia sobie ze słabościami.",
        "Budowanie charakteru: Cnota altruizmu.",
        "Lęk przed odrzuceniem: Jak go pokonać.",
        "Pieniądze jako środek do celu, nie jako cel.",
        "Rozwijanie mocnych stron, mimo słabości.",
        "Budowanie charakteru: Cnota miłosierdzia.",
        "Przezwyciężanie lęku przed zmianą.",
        "Pieniądze a satysfakcja: Rozbijanie mitów."
      ],
      finishContent: '',
      selectedSchema: null
    }
  },
  methods: {

    setTopic: function(){

      this.topic = this.selectedTopic

    },

    generateFinish: function() {

      if(!this.topic) return false;

      this.$refs.generatePost.disabled = true

      this.isFinish = true

      this.finishContent = ""
      this.result = ""

      var query = "";

      query = "Wciel się w mówcę motywacyjnego i napisz 5 wersji postu motywacyjnego na temat: " + this.topic + ", który motywuje, wzrusza i powoduje, że ludzie zaczynają przezywać emocje.";

      query = query + ". Wynik zapytania napisz koniecznie(!) w języku: " + this.lang;

      var doneText = "";

      this.axios.post(window.API_URL + "text/generateFromAI/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {

        let data = response.data

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }

        doneText = data.result;

        this.finishContent = doneText;

        this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

        this.$refs.generatePost.disabled = false
        this.generatedPostTitle = "Wygenerowane posty"

      }).catch(function() {

        this.$refs.generatePost.disabled = false

      })



    },

    copyResults: function() {

      this.$refs.finishContent.style.display = "block";

      this.$refs.finishContent.focus();

      document.execCommand('copy');

      this.$refs.finishContent.style.display = "none"

      window.scrollTo(0, 0)

      this.$bvToast.toast('Treść została skopiowana do schowka', {
        title: 'Skopiowano',
        autoHideDelay: 5000,
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-top-center',
        noCloseButton: true
      })

    }

  },
  computed: {

  },
  created() {

    this.$watch('finishContent', (value) => {

      var lines = value.split(/\r|\r\n|\n/);

      this.finishLinesCount = lines.length;

    })

  },
  mounted() {

    if (localStorage.generators_language) {

      this.lang = localStorage.generators_language;

    } else {

      localStorage.generators_language = "Polski"
      this.lang = "Polski"
    }

  },
  watch: {

    lang(data) {

      localStorage.generators_language = data;

    }

  }


}
</script>

<style>

.pointer { cursor: pointer; }
.bold { font-weight: 600 !important; }
code { color: #4e82f7 !important; }
</style>

