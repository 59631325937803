<template>

  <div class="pt-2">

    <div v-if="!selectedSchema">

      Wybierz rodzaj posta:

      <b-list-group class="mt-3">
        <b-list-group-item v-for="item in schema" class="pointer" :key="item.name" button @click="selectSchema(item)">

          <span class="material-symbols-outlined align-middle">article</span> {{ item.name }}

        </b-list-group-item>
      </b-list-group>

    </div>

    <div v-if="selectedSchema">

      <b-row align-v="center">

        <b-col lg="12">

          <h2>

            <button class="btn btn-white" size="sm" @click="selectedSchema = ''">
              <span class="material-symbols-outlined align-middle">
                keyboard_return
              </span>
            </button>

            {{ this.selectedSchema.name }}

          </h2>


          <div class="mt-4">

            <div class="mt-3">

              <label>{{ this.selectedSchema.label }}</label>

              <b-input-group>

                <b-form-input v-model="productName" ref="productName" :placeholder="this.selectedSchema.placeholder" v-on:keyup.enter="generateFinish()"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Wybierz język:</label>

              <b-input-group>

                <b-form-select v-model="lang" ref="lang" :options="languages"></b-form-select>

              </b-input-group>

            </div>

            <br/>

            <b-form-checkbox v-model="addEmoji" value="true">Dodaj emoji</b-form-checkbox>
            <b-form-checkbox v-model="addCallToFollow" value="true">Dodaj wezwanie do obserwowania</b-form-checkbox>
            <b-form-checkbox v-model="addHashtags" value="true">Dodaj hashtagi</b-form-checkbox>

          </div>

        </b-col>

      </b-row>

      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">

        </b-col>

        <b-col lg="6">

          <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
            Generuj
          </b-button>

        </b-col>

      </b-row>



      <div class="mt-2" >

        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje Twój post <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowany tekst</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">

            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>


        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>

    </div>

  </div>

</template>

<script>

import { languages } from './../../../../resources/languages.js';


export default {
  name: 'AppBlogPostAi',
  components: {
  },
  data() {
    return {
      isFinish: false,
      result: "",
      selectedText: '',
      productName: "",
      addEmoji: false,
      addCallToFollow: false,
      addHashtags: false,
      lang: "Polski",
      languages,
      finishContent: '',
      selectedSchema: null,
      schema: [
        {
          name: "7 porad",
          label: "Wpisz czego ma dotyczyć post np. nazwa produktu, usługi, zjawiska, branży.",
          placeholder: "np. fotowoltaika, dieta keto, masaż, działka budowlana",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł na temat [WSTAW]. Artykuł ma być napisany w stylu 7 porad na… Pisz językiem bezpośrednim. Język ma być prosty."
        },
        {
          name: "Rozwiązanie problemu krok po kroku",
          label: "Wpisz jaki problem twoich czytelników chcesz rozwiązać za pomocą artykułu",
          placeholder: "np. brak efektów diety, wysokie rachunki za prąd itd",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł w którym krok po kroku rozwiązujesz problem  [WSTAW]. Artykuł ma być napisany w stylu jak rozwiązać problem xyz w xx krokach… Pisz językiem bezpośrednim. Język ma być prosty. Artykuł ma mieć format krok 1, krok 2 itd."
        },
        {
          name: "Jak wybrać produkt w 5 krokach?",
          label: "Wpisz jaki produkt lub usługę wybiera twój potencjalny klient i jakiego produktu, usługi dotyczył będzie artykuł",
          placeholder: "np. mieszkanie, złoty pierścionek, sukienka na wesele, wzór na paznokcie",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym w 5 krokach pomagasz dokonać wyboru [WSTAW]. Artykuł ma być napisany w stylu jak wybrać xyz w 5 krokach. Pisz językiem bezpośrednim. Język ma być prosty. Artykuł ma mieć format krok 1, krok 2 itd."
        },
        {
          name: "5 sposobów na…",
          label: "Wpisz jaki problem twoich czytelników chcesz rozwiązać za pomocą artykułu",
          placeholder: "np. brak efektów diety, wysokie rachunki za prąd itd.",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym przedstawiasz 5 sposobów na rozwiązanie problemu [WSTAW]. Artykuł ma być napisany w stylu 5 sposobów na …. Pisz językiem bezpośrednim. Język ma być prosty."
        },
        {
          name: "7 sposobów na…",
          label: "Wpisz jaki problem twoich czytelników chcesz rozwiązać za pomocą artykułu",
          placeholder: "np. brak efektów diety, wysokie rachunki za prąd itd.",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym przedstawiasz 7 sposobów na rozwiązanie problemu [WSTAW]. Artykuł ma być napisany w stylu 7 sposobów na …. Pisz językiem bezpośrednim. Język ma być prosty."
        },
        {
          name: "Problem - rozwiązanie",
          label: "Wpisz jaki problem twoich czytelników chcesz rozwiązać za pomocą artykułu",
          placeholder: "np. brak efektów diety, wysokie rachunki za prąd itd.",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym przedstawiasz rozwiązanie problemu [WSTAW]. Artykuł ma być napisany w stylu problem - rozwiązanie. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Czego nie robić z…",
          label: "Wpisz jaki produkt lub usługę wybiera twój potencjalny klient i jakiego produktu, usługi dotyczył będzie artykuł",
          placeholder: "np. mieszkanie, złoty pierścionek, sukienka na wesele, wzór na paznokcie",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym napiszesz czego nie robić z [WSTAW]. Artykuł ma być napisany w stylu czego nie robić z xyz. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "5 błędów, gdy zaczynasz przygodę z...",
          label: "Wpisz z czym zaczyna przygodę twój klient",
          placeholder: "np. grą na gitarze, inwestycją w nieruchomości, dietą itp.",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym napiszesz o 5 błędach, jeżeli ktoś zaczyna przygodę z [WSTAW]. Artykuł ma być napisany w stylu 5 błędów, gdy zaczynasz przygodę z... Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "5 aplikacji, które pomogą Ci...",
          label: "Wpisz czym zajmuje się twój potencjalny klient",
          placeholder: "np. grą na gitarze, bieganiem, gotowaniem",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym polecasz 5 aplikacji dla osób zajmujących się [WSTAW]. Artykuł ma być napisany w stylu 5 aplikacji, które pomogą Ci w…. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Porównanie 2 produktów/usług",
          label: "Wstaw produkty lub usługi, które chcesz porównać ze sobą",
          placeholder: "np. sukienka z bawełny z sukienką z lnu",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym porównasz dwa produkty. W artykule porównaj [WSTAW] Napisz dla kogo jest dany produkt i czym się różnią. Artykuł ma być napisany w stylu porównanie 2 produktów. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Odpowiedź na pytanie (jak, dlaczego, ile)",
          label: "Wpisz pytanie, które  zadają twoi potencjalni klienci. Pytanie powinno zaczynać się od Jak, Dlaczego, Po co? Ile? Kiedy?",
          placeholder: "np. jak mam zarabiać więcej pieniędzy?",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym odpowiadasz na pytanie: [WSTAW]. Artykuł ma zaczynać się od tego pytania. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "5 błędów przy wyborze",
          label: "Wpisz jaki produkt lub usługę wybiera twój potencjalny klient i jakiego produktu, usługi dotyczył będzie artykuł",
          placeholder: "np. mieszkanie, złoty pierścionek, sukienka na wesele, wzór na paznokcie",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł długi artykuł, w którym napiszesz o 5 błędach przy wyborze [WSTAW]. Artykuł ma być napisany w stylu 5 błędów przy wyborze. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Jak osiągnąć cel krok po kroku",
          label: "Wpisz jaki cel chcą osiągnąć twoi potencjalni klienci za pomocą twoich produktów lub usług ",
          placeholder: "np. szczupłą sylwetkę, piękny ogród, wyższą sprzedaż.",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opisujesz jak czytelnik ma osiągnąć cel [WSTAW]. Artykuł ma być napisany w stylu jak osiągnąć cel krok po kroku. Pisz językiem bezpośrednim. Język ma być prosty."
        },
        {
          name: "10 cytatów na temat",
          label: "Wpisz w jakim obszarze działasz i na jaki temat masz napisać 10 cytatów ",
          placeholder: "np. sport, muzyka, inwestycje",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym wypisujesz 10 cytatów w obszarze [WSTAW]. Artykuł ma być napisany w stylu 10 genialnych cytatów na temat …. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Opinia na temat trendów",
          label: "Wpisz nowy trend w twojej branży ",
          placeholder: "np. sztuczna inteligencja w sprzedaży, automatyzacja w IT, kolor czerwony w modzie",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym napiszesz opinię na temat trendu [WSTAW]. Artykuł ma być napisany w stylu opinia na temat trendu. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Jak pokonać 5 najczęstszych wymówek ",
          label: "Wpisz w jakim obszarze twoi potencjalni klienci mają wymówki ",
          placeholder: "np. w utrzymaniu diety, bieganiu, systematycznym dodawaniu postów",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz 5 najczęstszych wymówek w obszarze [WSTAW] oraz podpowiedz, jak pokonać te poszczególne wymówki. Artykuł ma być napisany w stylu 5 najczęstszych wymówek i sposoby na ich pokonanie. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "5 najgorszych rad, które możesz otrzymać, jeżeli zaczynasz z…",
          label: "Wpisz z czym zaczyna przygodę twój czytelnik lub potencjalny klient ",
          placeholder: "np. z dietą, bieganiem, budową domu",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz 5 najgorszych rad które może otrzymać osoba, która zaczyna przygodę z [WSTAW]. Artykuł ma być napisany w stylu 5 najgorszych rad, które możesz otrzymać, jeżeli zaczynasz z…. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "5 Mitów na temat…",
          label: "Wpisz na jakie tematy powstają mity w twojej branży ",
          placeholder: "np. diety, skuteczności ćwiczeń, skutków ubocznych diety, szkodliwości kosmetyków",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz 5 mitów na temat: [WSTAW] Artykuł ma być napisany w stylu 5 najgorszych Mitów na temat… Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Dlaczego klienci kupują ten produkt?",
          label: "Wpisz jaki produkt lub usługę wybiera twój potencjalny klient i jakiego produktu, usługi dotyczył będzie artykuł",
          placeholder: "np. mieszkanie, złoty pierścionek, sukienka na wesele, wzór na paznokcie",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz 5 powodów dla których klienci kupują [WSTAW] Artykuł ma być napisany w stylu 5 powodów dla których klienci kupują produkt xyz. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "7 rzeczy, które zapamiętałem z książki…",
          label: "Wpisz tytuł i autora książki popularnej w twojej branży",
          placeholder: "np. „Biedny ojciec, bogaty ojciec” Robert Kiyosaki",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz 7 rzeczy które zapamiętałeś z książki [WSTAW]. Artykuł ma być napisany w stylu 7 rzeczy, które zapamiętałem z książki xyz. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Historia klienta, któremu pomogłeś",
          label: "Opisz krótko sytuację, w której twój produkt lub usługa pomogła klientowi rozwiązać życiowy problem ",
          placeholder: "np. dzięki instalacji fotowoltaiki klient zmniejszył rachunki z prąd dzięki czemu miał pieniądze na edukację córki",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz sytuację klienta któremu pomogłeś. Oto krótki opis tej sytuacji [WSTAW]. Artykuł ma być napisany w stylu storytellingowym i zawierać elementy dobrej historii. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Za co płaci klient - 7 rzeczy",
          label: "Wpisz jaki produkt lub usługę wybiera twój potencjalny klient i jakiego produktu, usługi dotyczył będzie artykuł.",
          placeholder: "np. mieszkanie, złoty pierścionek, sukienka na wesele, wzór na paznokcie.",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz za co tak naprawdę płaci klient kupujący [WSTAW]. Artykuł ma być napisany w stylu 7 rzeczy, za które płacisz kupując …. Pisz językiem bezpośrednim. Język ma być prosty."
        },
        {
          name: "Artykuł o różnicach pomiędzy elementami",
          label: "Wpisz produkty",
          placeholder: "np. złoty i srebrny pierścionek ",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz różnicę w szczegółowej budowie, składzie itd. tych dwóch produktów. Produkty to [WSTAW]. Artykuł ma być napisany w stylu szczegółowe różnice pomiędzy produktami. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Lista 10 porad, które dałbym poczatkującej osobie",
          label: "Wpisz w jakiej dziedzinie osoba czytając artykuł jest poczatkująca",
          placeholder: "np. inwestowanie, gra na gitarze, mlm, terapia, odchudzanie",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz jakie 10 rad dałbyś osobie, która jest poczatkująca w [WSTAW]. Jakie 10 rad dałbym osobie, która jest poczatkująca w …Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Jak zaoszczędzić czas dzięki produktowi",
          label: "Wpisz jaki produkt lub usługę wybiera twój potencjalny klient i jakiego produktu, usługi dotyczył będzie artykuł",
          placeholder: "np. mieszkanie, złoty pierścionek, sukienka na wesele, wzór na paznokcie.",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz jak klient zaoszczędzi czas kupując [WSTAW]. Artykuł ma być napisany w stylu Jak zaoszczędzisz czas kupując… Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Jak zaoszczędzić pieniądze dzięki produktowi",
          label: "Wpisz jaki produkt lub usługę wybiera twój potencjalny klient i jakiego produktu, usługi dotyczył będzie artykuł",
          placeholder: "np. mieszkanie, złoty pierścionek, sukienka na wesele, wzór na paznokcie",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz, jak klient zaoszczędzi pieniądze kupując [WSTAW]. Artykuł ma być napisany w stylu Jak zaoszczędzisz pieniądze kupując… Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Lista kontrolna zakupu",
          label: "Wpisz jaki produkt lub usługę wybiera twój potencjalny klient i jakiego produktu, usługi dotyczył będzie artykuł",
          placeholder: "np. mieszkanie, złoty pierścionek, sukienka na wesele, wzór na paznokcie.",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz listę kontrolną tzw. Checklistę przed zakupem [WSTAW]. Artykuł ma być napisany w stylu Lista kontrolna zakupu… Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Lista 7 pytań, które zadają klienci na temat produktu lub usługi",
          label: "Wpisz jaki produkt lub usługę wybiera twój potencjalny klient i jakiego produktu, usługi dotyczył będzie artykuł",
          placeholder: "np. mieszkanie, złoty pierścionek, sukienka na wesele, wzór na paznokcie",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz 7 pytań które najczęściej zadają klienci przed zakupem [WSTAW]. Artykuł ma być napisany w stylu lista 7 pytań przed zakupem… Pisz językiem bezpośrednim. Język ma być prosty"
        },
        {
          name: "Te 7 rzeczy chciałbym wiedzieć zanim zaczynałem przygodę z …",
          label: "Wpisz z czym zaczyna przygodę twój klient",
          placeholder: "np. grą na gitarze, inwestycją w nieruchomości, dietą itp.",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz 7 rzeczy które chciałbyś wiedzieć zanim zacząłeś przygodę z [WSTAW]. Te 7 rzeczy chciałbym wiedzieć zanim zaczynałem przygodę z … Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "7 terminów w branży które wymagają wyjaśnienia",
          label: "Wpisz w jakiej branży lub obszarze działasz ",
          placeholder: "np. dieta, fitness, oze, nieruchomości",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz 7 terminów, których używa się w [WSTAW] i wytłumacz je tak żeby zrozumiało 12 letnie dziecko. Tłumacz na zasadzie - to jest trochę jak. Napisz artykuł w stylu 7 terminów w branży które wymagają wyjaśnienia. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Dlaczego nie polecasz tego rozwiązania? ",
          label: "Wpisz jakiego rozwiązania nie polecasz a które jest popularne wśród klientów ",
          placeholder: "np. pigułki na odchudzanie, kupowanie fanów, ogrzewanie gazowe",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz, dlaczego nie polecasz takiego rozwiązania jak: [WSTAW]. Napisz artykuł w stylu dlaczego nie polecasz tego rozwiązania. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Dla kogo jest to produkt a dla kogo nie",
          label: "Wpisz jaki produkt lub usługę wybiera twój potencjalny klient i jakiego produktu, usługi dotyczył będzie artykuł",
          placeholder: "np. mieszkanie, złoty pierścionek, sukienka na wesele, wzór na paznokcie",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz, komu polecasz produkt [WSTAW] a komu nie. Napisz artykuł w stylu Dla kogo jest to produkt a dla kogo nie.. Pisz językiem bezpośrednim. Język ma być prosty."
        },
        {
          name: "Rozpocznij debatę nt. tego jaka jest przyszłość w twojej branży",
          label: "Wpisz w jakiej branży lub obszarze działasz, jakie zjawiska występują w twojej branży",
          placeholder: "np. architektura, moda, marketing, sztuczna inteligencja, fryzjerstwo",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz przyszłość [WSTAW BRANŻĘ/ZJAWISKO] i zachęć do debaty nad przyszłością [WSTAW]. Napisz artykuł w stylu Rozpocznij debatę nt. tego jaka jest przyszłość w twojej branży. Pisz językiem bezpośrednim. Język ma być prosty. "
        },
        {
          name: "Co wyróżnia was na tle konkurencji?",
          label: "Wpisz czym zajmuje się twoja firma",
          placeholder: "np. marketing internetowy",
          query:  "Wciel się w autora bloga i pisz w ciekawy sposób. Napisz artykuł, w którym opiszesz przyszłość czym wyróżnia się Wasza firma zajmująca się [WSTAW]. Napisz artykuł w stylu Co wyróżnia was na tle konkurencji. Pisz językiem bezpośrednim. Język ma być prosty. "
        }
      ]
    }
  },
  methods: {

    selectSchema: function(item) {

      this.selectedSchema = item
    },

    generateFinish: function() {

      if(!this.productName) return false;

      this.$refs.generatePost.disabled = true

      this.isFinish = true

      this.finishContent = ""
      this.result = ""

      var query = "";


      query = this.selectedSchema.query;


      if(this.$refs.productName)
        query = query.replaceAll('[WSTAW]', this.$refs.productName.value)


      if(this.addEmoji) {

        query = query + "Dodaj emoji."

      }

      if(this.addCallToFollow) {

        query = query + "Dodaj wezwanie do obserwowania."

      }

      if(this.addHashtags) {

        query = query + "Dodaj hashtagi na końcu."

      }

      query = query + "Napisz to koniecznie(!) w języku: " + this.lang;


      var doneText = "";

      this.axios.post(window.API_URL + "text/generateFromAI/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {

        let data = response.data

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }

        doneText = data.result;

        this.finishContent = doneText;

        this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

        this.$refs.generatePost.disabled = false
        this.generatedPostTitle = "Wygenerowany tekst"

      }).catch(function() {

        this.$refs.generatePost.disabled = false

      })



    },

    loadFromExample: function(keyword) {

      this.topic = keyword

    },

    copyResults: function() {

      this.$refs.finishContent.style.display = "block";

      this.$refs.finishContent.focus();

      document.execCommand('copy');

      this.$refs.finishContent.style.display = "none"

      window.scrollTo(0, 0)

      this.$bvToast.toast('Post został skopiowany do schowka', {
        title: 'Skopiowano',
        autoHideDelay: 5000,
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-top-center',
        noCloseButton: true
      })

    }

  },
  computed: {

  },
  created() {

    this.$watch('finishContent', (value) => {

      var lines = value.split(/\r|\r\n|\n/);

      this.finishLinesCount = lines.length;

    })

  },
  mounted() {

    if (localStorage.generators_language) {

      this.lang = localStorage.generators_language;

    } else {

      localStorage.generators_language = "Polski"
      this.lang = "Polski"
    }

  },
  watch: {

    lang(data) {

      localStorage.generators_language = data;

    }

  }

}
</script>

<style>

.pointer { cursor: pointer; }
.bold { font-weight: 600 !important; }
</style>

